import { cn } from "utils/utils.ts";

export const LoadingSpinner = ({
  loading,
  className = "",
}: {
  loading: boolean;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        `pointer-events-none size-5 flex-shrink-0 text-current transition-opacity ${
          loading ? "opacity-100" : "hidden opacity-0"
        }`,
        className,
      )}
    >
      <svg
        className="-ml-1 mr-3 size-full animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
      >
        <circle
          className="opacity-25"
          cx={12}
          cy={12}
          r={10}
          stroke="currentColor"
          strokeWidth={4}
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 0 1 8-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0 1 4 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
};
